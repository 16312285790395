import {
  EventType,
  SelectLocationProperties,
} from "@/analytics/analytics-events";
import { useSceneEvents } from "@/components/common/scene-events-context";
import { CameraAnimation } from "@/components/r3f/animations/camera-animation";
import { useCenterCameraOnPlaceholders } from "@/hooks/use-center-camera-on-placeholders";
import { obbToPlanes } from "@/hooks/use-clipping-planes";
import { useDetectTooManyRedraws } from "@/hooks/use-detect-too-many-redraws";
import { useCurrentAreaClippingBox } from "@/hooks/use-object-bounding-box";
import { ModeSceneProps } from "@/modes/mode";
import {
  useCurrentScene,
  useIsCurrentAreaLoading,
} from "@/modes/mode-data-context";
import { useCached3DObjectsIfExists } from "@/object-cache";
import { selectMeasurements } from "@/store/measurement-tool-selector";
import { changeMode } from "@/store/mode-slice";
import { setWalkSceneFilter } from "@/store/modes/walk-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { PickingTools } from "@/tools/picking-tools";
import { usePickingToolsCallbacks } from "@/tools/use-picking-tools-callbacks";
import { SceneFilter } from "@/types/scene-filter";
import { getCameraAnimationTime } from "@/utils/camera-animation-time";
import {
  OrthoFrustum,
  selectIElementWorldPosition,
  useReproportionCamera,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  IElementImg360,
  IElementSection,
  isIElementImg2d,
  isIElementModel3d,
} from "@faro-lotv/ielement-types";
import { reproportionCamera } from "@faro-lotv/lotv";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box3, Camera, OrthographicCamera, Quaternion, Vector3 } from "three";
import { SheetModeSceneBase } from "./sheet-scene-base";
import { SheetModeInitialState } from "./sheet-state";

function useCenterCameraOnLookAtPano(
  camera: Camera,
  panos: IElementImg360[],
  initialState?: SheetModeInitialState,
): void {
  assert(
    camera instanceof OrthographicCamera,
    "Sheet mode camera should be orthographic",
  );

  const position = useAppSelector(
    selectIElementWorldPosition(initialState?.lookAtId),
  );
  const { size } = useThree();

  if (!initialState || !initialState.lookAtId) return;

  const lookAtPano = panos.find((p) => p.id === initialState.lookAtId);
  if (!lookAtPano) return;

  camera.position.x = position[0];
  camera.position.z = position[2];
  camera.updateMatrix();
  camera.right = 3;
  camera.left = -3;
  camera.top = 3;
  camera.bottom = -3;
  reproportionCamera(camera, size.width / size.height);
}

/** Value used to increase the size of the bounding box in order to add some padding while calculating zoom */
const ZOOM_PADDING_FACTOR = 1.1;

type CameraTargetData = {
  /** Target position to which the camera should be moved */
  targetPosition: Vector3;

  /** Target quaternion of the camera */
  targetQuaternion?: Quaternion;

  /** Target frustum of the camera */
  frustum?: OrthoFrustum;

  /** Desired zoom value of the camera */
  zoom: number;
};

/**
 * @returns The main Scene for the Sheet Mode
 */
export function SheetScene({
  initialState,
}: ModeSceneProps<SheetModeInitialState>): JSX.Element {
  useDetectTooManyRedraws("SheetScene");

  // Get the relevant data for the mode from the store
  const isLoading = useIsCurrentAreaLoading();
  const {
    activeSheets,
    activeSheetForElevation,
    panos,
    paths,
    referenceElement,
    annotations,
  } = useCurrentScene();

  // Get active pano and sheet from app store
  const dispatch = useAppDispatch();

  const showPanoInWalkMode = useCallback(
    (target: IElementImg360) => {
      dispatch(setActiveElement(target.id));
      dispatch(setWalkSceneFilter(SceneFilter.Pano));
      dispatch(changeMode("walk"));
    },
    [dispatch],
  );

  const camera = useThree((s) => s.camera);
  useReproportionCamera(camera);

  useCenterCameraOnLookAtPano(camera, panos, initialState);

  const areaBox = useCurrentAreaClippingBox();

  // Use the first visible sheet to center the camera
  // https://faro01.atlassian.net/browse/CADBIM-1205 will later take all visible layers into account.
  const sheetForCameraCenter = activeSheets.length
    ? activeSheets[0]
    : undefined;

  const cameraData = useCenterCameraOnPlaceholders({
    areaVolume: areaBox,
    sheetElement: sheetForCameraCenter,
    placeholders: panos,
  });

  // The sheet mode currently only clips using an area's volume, since the user has no way to edit a custom one.
  const clippingPlanes = useMemo(
    () => (areaBox ? obbToPlanes(areaBox) : undefined),
    [areaBox],
  );

  // UseLayoutEffects are re-triggered every time the parent suspense renders the fallback component, so
  // it's much safer to wrap the recentering logic in a useEffect
  useEffect(() => {
    if (camera instanceof OrthographicCamera && !initialState) {
      setCameraTargetData({
        targetPosition: cameraData.position,
        targetQuaternion: cameraData.quaternion,
        zoom: camera.zoom,
        frustum: cameraData.frustum,
      });
      // As we assign the frustrum we need to put manual to true
      Object.assign(camera, { manual: true });
    }
    // We want to center every-time the current active sheet changes or the loading finishes
    // This hook depends on area.id and not on sheet, so that when changing the active sheet
    // the camera does not move
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState, isLoading]);

  const [cameraTargetData, setCameraTargetData] = useState<CameraTargetData>();

  const frameBox = useCallback(
    (boundingBox: Box3) => {
      if (!(camera instanceof OrthographicCamera)) {
        throw Error("Expected orthographic camera");
      }
      const targetPosition = boundingBox.getCenter(new Vector3());
      // Keeping the height of the camera as is so that it doesn't move too close to path
      targetPosition.y = camera.position.y;

      // Convert the bounding box co-ordinates into camera space
      const pathBboxInCameraSpace = boundingBox
        .clone()
        .applyMatrix4(camera.matrixWorldInverse);

      const boundingBoxWidth =
        pathBboxInCameraSpace.max.x - pathBboxInCameraSpace.min.x;
      const boundingBoxHeight =
        pathBboxInCameraSpace.max.y - pathBboxInCameraSpace.min.y;

      const frustumWidth = camera.right - camera.left;
      const frustumHeight = camera.top - camera.bottom;

      const widthScale =
        frustumWidth / (boundingBoxWidth * ZOOM_PADDING_FACTOR);
      const heightScale =
        frustumHeight / (boundingBoxHeight * ZOOM_PADDING_FACTOR);

      setCameraTargetData({
        targetPosition,
        zoom: Math.min(widthScale, heightScale),
      });
    },
    [camera],
  );

  const shouldAnimateCameraToFitObject = useCallback(
    (ev: ThreeEvent<MouseEvent>, path: IElementSection, boundingBox: Box3) => {
      dispatch(setActiveElement(path.id));
      frameBox(boundingBox);
    },
    [dispatch, frameBox],
  );

  const mapAnnotations = useMemo(
    () =>
      annotations.filter((a) => !isIElementModel3d(a) && !isIElementImg2d(a)),
    [annotations],
  );

  const visibleSheetsIds = useMemo(
    () => activeSheets.map((s) => s.id),
    [activeSheets],
  );
  const measurements = useAppSelector(
    selectMeasurements(visibleSheetsIds),
    isEqual,
  );

  const { lookAt } = useSceneEvents();
  useTypedEvent(lookAt, (position: Vector3) => {
    // Frame a box of 10m x 10m around the position to look at
    frameBox(new Box3().expandByPoint(position).expandByScalar(10));
  });

  // Prevents the CameraAnimation from restarting due to a change in dependencies
  const clearCameraTargetData = useCallback(
    () => setCameraTargetData(undefined),
    [],
  );

  const sheetObjects = useCached3DObjectsIfExists(activeSheets);
  const [isPickingToolEnabled, setIsPickingToolEnabled] = useState(false);
  const pickingToolsCallbacks = usePickingToolsCallbacks();

  return (
    <>
      <SheetModeSceneBase
        sheets={sheetObjects}
        sheetElementForElevation={activeSheetForElevation}
        pathElement={referenceElement}
        paths={paths}
        panos={panos}
        annotations={mapAnnotations}
        measurements={measurements}
        clippingPlanes={clippingPlanes}
        transparentSheet
        onPlaceholderClicked={(e) => {
          Analytics.track(EventType.selectLocation, {
            via: SelectLocationProperties.sheetView,
          });

          showPanoInWalkMode(e);
        }}
        onPathActivated={shouldAnimateCameraToFitObject}
        pickingToolsCallbacks={
          isPickingToolEnabled ? pickingToolsCallbacks : undefined
        }
      />

      <PickingTools
        ref={pickingToolsCallbacks.tools}
        activeModels={sheetObjects}
        onActiveToolChanged={(activeToolAndPicking) =>
          setIsPickingToolEnabled(activeToolAndPicking.isPickingToolActive)
        }
      />

      {cameraTargetData && (
        <CameraAnimation
          position={cameraTargetData.targetPosition}
          quaternion={cameraTargetData.targetQuaternion}
          frustum={cameraTargetData.frustum}
          onAnimationFinished={clearCameraTargetData}
          zoom={cameraTargetData.zoom}
          duration={getCameraAnimationTime(
            camera,
            cameraTargetData.targetPosition,
            { min: 0.5, max: 1 },
          )}
        />
      )}
    </>
  );
}
